import {ProductRange} from "../../../../../interfaces/catalog-interfaces";
import {I18n, useTranslation, withTranslation} from "next-i18next";
import styles from "../../items-list.module.scss";
import Link from "next/link";
import {Img} from "react-image";
import {connect} from 'react-redux';
import ContentLoader from "react-content-loader";
import _variables from "../../../../../styles/global.module.scss";
import Price from "../../../../price/price";
import {ShopState} from "../../../../../interfaces/store-interfaces";
import {addToCart} from "../../../../../store/action";

function ItemRange(props: { rangeItem: ProductRange, i18n: I18n }) {

    const {t} = useTranslation('common');

    return (
        <div key={props.rangeItem.defaultProductSlug}
             className={styles['product']}>
            <Link href={`/product/${props.rangeItem.defaultProductSlug}`}>
                <div className={styles['image']}>
                    <Img src={props.rangeItem.mainImage} alt={props.rangeItem.name} loader={<ContentLoader
                        speed={4}
                        style={{
                            width: 230,
                            height: 230,
                            borderTopLeftRadius: 30,
                            borderTopRightRadius: 30,
                            objectFit: 'cover',
                            opacity: 0.5
                        }}
                        backgroundColor={_variables.mainColorLight}
                        foregroundColor={_variables.mainColor}
                    >
                        <rect x="0" y="0" rx="1" ry="15" width="100%" height="100%"/>
                    </ContentLoader>}/>
                </div>
            </Link>
            <div className={styles['infos']}>
                <Link href={`/product/${props.rangeItem.defaultProductSlug}`}><p
                    className={styles['name']}>{props.rangeItem.name}</p></Link>
                <div className={styles['rangebottom']}>
                    <div className={styles['left']}>
                        {!props.rangeItem.allSamePrice &&
                        <div className={styles['fromprice']}>{t("CATALOG_PRICE_FROM")}</div>}
                        <Price price={props.rangeItem.minAmountWithTax} page={'catalog'}/>
                    </div>
                    <div className={styles['right']}>
                        {props.rangeItem.nbItems} {props.rangeItem.nbItems > 1 ? t("CATALOG_RANGE_ITEMS") : t("CATALOG_RANGE_ITEM")}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapState = (state: ShopState) => ({
    currentCurrency: state.currentCurrency,
    shoppingCart: state.shoppingCart
})

const mapDispatch = (dispatch) => {
    return {
        addToCart: (product: { idP: number, quantity: number }) => dispatch(addToCart(product))
    }
}

export default connect(mapState, mapDispatch)(withTranslation('common')(ItemRange));
