import styles from './price.module.scss'
import {connect} from 'react-redux'
import {ShopState} from '../../interfaces/store-interfaces';
import {internalization} from '../../config-internalization';
import {useEffect, useState} from 'react';


function Price(props: {
    price: number,
    currentCurrency: string,
    page: string,
    strike?: boolean,
    negative?: boolean,
    customCurrency?: string
}) {


    const [price, setPrice] = useState<string>('');

    useEffect(() => {
        manageCurrency()
    }, [])

    const manageCurrency = (): void => {
        let currency = props.customCurrency;
        if (currency === undefined) {
            currency = props.currentCurrency;
        }
        switch (currency) {
            case 'EUR':
                setPrice(props.price.toFixed(2).replace('.', ',') + internalization.currencies['EUR'].symbol)
                break;
            case 'USD':
                setPrice(internalization.currencies['USD'].symbol + (props.price).toFixed(2))
                break;
            case 'CHF':
                if (Number.isInteger(props.price)) {
                    setPrice(internalization.currencies['CHF'].symbol + " " + (props.price) + ".-")
                } else {
                    setPrice(internalization.currencies['CHF'].symbol + " " + (props.price).toFixed(2))
                }
                break;
            default:
                setPrice('');
        }
    }

    return (
        <>
            {props.page === 'catalog' ?
                (props.strike) &&
                <p className={`${styles['price-catalog']} ${styles['strikethrough']} ${styles['promo']}`}> {price} </p> ||
                <p className={styles['price-catalog']}> {price} </p>
                : null}
            {props.page === 'single-product' ?
                (props.strike) &&
                <p className={`${styles['price-single-product']} ${styles['strikethrough']} ${styles['promo']}`}> {price} </p> ||
                <p className={styles['price-single-product']}> {price} </p> : null}
            {props.page === 'cart' &&
                <p className={styles['price-cart']}> {price} </p>}
            {props.page === 'cart-summary' ?
                (
                    (props.negative && props.strike) &&
                    <p className={`${styles['price-cart-summary']} ${styles['strikethrough']}`}> -{price} </p>
                ) ||
                (
                    props.strike &&
                    <p className={`${styles['price-cart-summary']} ${styles['strikethrough']}`}> {price} </p>)
                || (
                    props.negative &&
                    <p className={styles['price-cart-summary']}> -{price} </p>
                )
                ||
                (
                    (!props.negative && !props.strike) &&
                    <p className={styles['price-cart-summary']}> {price} </p>
                )
                : null}
            {props.page === 'order' &&
                <p className={styles['price-order']}> {price} </p>}
            {props.page === 'order-total' &&
                <> {price} </>}
            {props.page === 'my-account-h' &&
                <span className={props.strike ? `${styles['my-account-h']}  ${styles['strikethrough']}` : `${styles['my-account-h']}`}>
                    {price}
                </span>
            }
            {props.page === 'my-account' &&
                <span className={props.strike ? `${styles['my-account']}  ${styles['strikethrough']}` : `${styles['my-account']}`}>
                    {price}
                </span>
            }
        </>
    );
}

const mapState = (state: ShopState) => ({
    currentCurrency: state.currentCurrency
})

export default connect(mapState, null)(Price)
