import {I18n, useTranslation, withTranslation} from "next-i18next";
import {Product} from "../../../../../interfaces/catalog-interfaces";
import styles from "../../items-list.module.scss";
import Link from "next/link";
import {Img} from "react-image";
import ContentLoader from "react-content-loader";
import _variables from "../../../../../styles/global.module.scss";
import Price from "../../../../price/price";
import {ShopState} from "../../../../../interfaces/store-interfaces";
import {addToCart} from "../../../../../store/action";
import {useRouter} from "next/router";
import {connect} from 'react-redux';

function ItemProduct(props: { productItem: Product, i18n: I18n, addToCart: any, shoppingCart: {} }) {

    const {t} = useTranslation('common');
    const router = useRouter();

    const addProductToCart = (productId) => {
        props.addToCart({idP: productId, quantity: 1})
    }

    const isOnShoppingCart = (productId): boolean => {
        return props.shoppingCart.hasOwnProperty(productId) && props.shoppingCart[productId]["quantity"] > 0;
    }

    return (
        <div key={props.productItem.productId}
             className={isOnShoppingCart(props.productItem.productId) ? `${styles['product']} ${styles['product-added']}` : styles['product']}>
            <Link href={`/product/${props.productItem.productSlug}`}>
                <div className={styles['image']}>
                    {
                        (props.productItem.price.originalAmountWithTax !== null && !isOnShoppingCart(props.productItem.productId)) ?
                            <div className={styles['sticker-promo']}>{t('CATALOG_DISCOUNT')}</div> :
                            <div/>
                    }
                    <Img src={props.productItem.mainImage} alt={props.productItem.name} loader={<ContentLoader
                        speed={4}
                        style={{
                            width: 230,
                            height: 230,
                            borderTopLeftRadius: 30,
                            borderTopRightRadius: 30,
                            objectFit: 'cover',
                            opacity: 0.5
                        }}
                        backgroundColor={_variables.mainColorLight}
                        foregroundColor={_variables.mainColor}
                    >
                        <rect x="0" y="0" rx="1" ry="15" width="100%" height="100%"/>
                    </ContentLoader>}/>
                </div>
            </Link>
            <div className={styles['infos']}>
                {
                    props.productItem.availableInStock ? (
                        <div className={styles['buy']}
                             onClick={() => !isOnShoppingCart(props.productItem.productId) ? addProductToCart(props.productItem.productId) : router.push(`/product/${props.productItem.productSlug}`)}>
                            {
                                !isOnShoppingCart(props.productItem.productId) &&

                                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="43.5"
                                     viewBox="0 0 41 43.5">
                                    <g id="buy-addto" transform="translate(-595 -893)">
                                        <path id="shopping-bag"
                                              d="M7.25,5.5h24.5A1.75,1.75,0,0,1,33.5,7.25v24.5a1.75,1.75,0,0,1-1.75,1.75H7.25A1.75,1.75,0,0,1,5.5,31.75V7.25A1.75,1.75,0,0,1,7.25,5.5ZM2,7.25A5.25,5.25,0,0,1,7.25,2h24.5A5.25,5.25,0,0,1,37,7.25v24.5A5.25,5.25,0,0,1,31.75,37H7.25A5.25,5.25,0,0,1,2,31.75ZM19.5,19.5c-4.832,0-8.75-4.7-8.75-10.5h3.5c0,4.491,2.921,7,5.25,7s5.25-2.509,5.25-7h3.5C28.25,14.8,24.332,19.5,19.5,19.5Z"
                                              transform="translate(593 891)"
                                              fill={_variables.colorBase} fillRule="evenodd"/>
                                        <g id="add" transform="translate(610 910.5)">
                                            <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="11.5"
                                                     cy="12" rx="11.5" ry="12"
                                                     transform="translate(1.5 1)" fill={_variables.bagSvgEllipseColor}/>
                                            <path id="Path_5" data-name="Path 5"
                                                  d="M2,15A13,13,0,1,1,15,28,13,13,0,0,1,2,15ZM15,4.6A10.4,10.4,0,1,0,25.4,15,10.4,10.4,0,0,0,15,4.6Z"
                                                  transform="translate(-2 -2)"
                                                  fill={_variables.mainColor} fillRule="evenodd"/>
                                            <path id="Path_6" data-name="Path 6"
                                                  d="M16.5,7.5a1.5,1.5,0,0,0-3,0v6h-6a1.5,1.5,0,0,0,0,3h6v6a1.5,1.5,0,0,0,3,0v-6h6a1.5,1.5,0,0,0,0-3h-6Z"
                                                  transform="translate(-2 -2)"
                                                  fill={_variables.mainColor} fillRule="evenodd"/>
                                        </g>
                                    </g>
                                </svg>
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35"
                                 viewBox="0 0 35 35">
                                <g id="buy-addedto" transform="translate(-1243 -1388)">
                                    <path id="shopping-bag"
                                          d="M7.25,5.5h24.5A1.75,1.75,0,0,1,33.5,7.25v24.5a1.75,1.75,0,0,1-1.75,1.75H7.25A1.75,1.75,0,0,1,5.5,31.75V7.25A1.75,1.75,0,0,1,7.25,5.5ZM2,7.25A5.25,5.25,0,0,1,7.25,2h24.5A5.25,5.25,0,0,1,37,7.25v24.5A5.25,5.25,0,0,1,31.75,37H7.25A5.25,5.25,0,0,1,2,31.75ZM19.5,19.5c-4.832,0-8.75-4.7-8.75-10.5h3.5c0,4.491,2.921,7,5.25,7s5.25-2.509,5.25-7h3.5C28.25,14.8,24.332,19.5,19.5,19.5Z"
                                          transform="translate(1241 1386)"
                                          fill={_variables.mainColor} fillRule="evenodd"/>
                                    <g id="check" transform="translate(1256.225 1409.034)">
                                        <path id="check-2" data-name="check"
                                              d="M10.586,13.414,7.757,10.586,6.343,12l4.243,4.243,7.071-7.071L16.243,7.757Z"
                                              transform="translate(-6.343 -7.757)"
                                              fill={_variables.mainColor}/>
                                    </g>
                                </g>
                            </svg>

                        </div>) : (
                        <div className={styles['out-of-stock']}>
                            <span className={styles['info']}>{t('CATALOG_OUT_OF_STOCK')}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="43.5"
                                 viewBox="0 0 41 43.5">
                                <g id="buy-addto" transform="translate(-595 -893)">
                                    <path id="shopping-bag"
                                          d="M7.25,5.5h24.5A1.75,1.75,0,0,1,33.5,7.25v24.5a1.75,1.75,0,0,1-1.75,1.75H7.25A1.75,1.75,0,0,1,5.5,31.75V7.25A1.75,1.75,0,0,1,7.25,5.5ZM2,7.25A5.25,5.25,0,0,1,7.25,2h24.5A5.25,5.25,0,0,1,37,7.25v24.5A5.25,5.25,0,0,1,31.75,37H7.25A5.25,5.25,0,0,1,2,31.75ZM19.5,19.5c-4.832,0-8.75-4.7-8.75-10.5h3.5c0,4.491,2.921,7,5.25,7s5.25-2.509,5.25-7h3.5C28.25,14.8,24.332,19.5,19.5,19.5Z"
                                          transform="translate(593 891)" fill={_variables.colorBase}
                                          fillRule="evenodd"/>
                                    <g id="add" transform="translate(610 910.5)">
                                        <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="11.5"
                                                 cy="12" rx="11.5" ry="12"
                                                 transform="translate(1.5 1)" fill={_variables.bagSvgEllipseColor}/>
                                        <path id="Path_5" data-name="Path 5"
                                              d="M2,15A13,13,0,1,1,15,28,13,13,0,0,1,2,15ZM15,4.6A10.4,10.4,0,1,0,25.4,15,10.4,10.4,0,0,0,15,4.6Z"
                                              transform="translate(-2 -2)" fill="grey"
                                              fillRule="evenodd"/>
                                        <path id="Path_6" data-name="Path 6"
                                              d="M16.5,7.5a1.5,1.5,0,0,0-3,0v6h-6a1.5,1.5,0,0,0,0,3h6v6a1.5,1.5,0,0,0,3,0v-6h6a1.5,1.5,0,0,0,0-3h-6Z"
                                              transform="translate(-2 -2)" fill="grey"
                                              fillRule="evenodd"/>
                                    </g>
                                </g>
                            </svg>
                        </div>)
                }
                <Link href={`/product/${props.productItem.productSlug}`}><p
                    className={styles['name']}>{props.productItem.name}</p></Link>
                <div className={styles['twoprices']}>
                    {props.productItem.price.originalAmountWithTax !== null &&
                    <Price price={props.productItem.price.originalAmountWithTax} page={'catalog'}
                           strike={true}/>}
                    <Price price={props.productItem.price.amountWithTax} page={'catalog'}/>
                </div>
            </div>
        </div>
    )
}

const mapState = (state: ShopState) => ({
    currentCurrency: state.currentCurrency,
    shoppingCart: state.shoppingCart
})

const mapDispatch = (dispatch) => {
    return {
        addToCart: (product: { idP: number, quantity: number }) => dispatch(addToCart(product))
    }
}

export default connect(mapState, mapDispatch)(withTranslation('common')(ItemProduct));
