import styles from './loader.module.scss'

export default function Loader() {
    return (
        <div className={styles["cs-loader"]}>
            <div className={styles["cs-loader-inner"]}>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
            </div>
        </div>
    );
}